input,textarea, select, button, h1, h2, h3, h4, h5, h6
  m()
  font-weight: 300

html,body
  font-size 62.5%
  m()
  font-weight: 300

@media only screen and (max-width: 1300px) and (min-width: 1025px)
  html,body
    font-size 50%

