/*
p -- page
s -- section
b -- block
pr -- block preview (pr-xxs, pr-xs, pr-s, pr-m, pr) if different sizes on site
f -- form
m -- menu
l -- list
sl -- sliders
svg -- svg grafix
i -- etc grafix
a -- img links, lone links, logos
t -- tables
title -- default titles
btn -- buttons
msg -- message - not content
modal -- modal windows
_error-*
_success-*
*/

@import 'settings/variables'
@import 'settings/mixin'
@import 'settings/optimize'
@import 'settings/fonts'
@import 'settings/default'

//@import '../../' + imgFolder +'/sprite'

@import 'vendor/jquery.formstyler'
@import 'vendor/slick'

@import 'etc/helpers'
@import 'etc/svg'
@import 'etc/titles'

@import '../../components/**/*'
