.s-menu
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  width: 100%
  height: 100%
  padding: 50px
  z-index: 2
  background-image: linear-gradient(316deg, #eef6e4, #d1e5bb);
  display: flex
  flex-direction column
  align-items center
  justify-content center
  transform translateY(-110%)
  opacity: 0
  transition transform .001s ease-out .35s, opacity .35s ease-out
  ._open-menu &
    transform translateY(0)
    opacity: 1
    transition: opacity .35s ease-out

@media (max-width: 1024px)
  .s-menu
    padding: 40px

@media (max-width: 640px)
  .s-menu
    padding: 30px

@media (orientation: landscape) and (max-width: 740px)
  .s-menu
    padding: 10px 30px
