.s-story
  .container
    padding 11.3% 100px 19%
    max-width: 1150px;
    display: flex
    align-items flex-end
    justify-content center
    flex-direction column
  &__inner
    max-width 460px
  h2
    font-size: 24px;
    letter-spacing: -1.2px
    color: #33691e;
    margin-bottom: 19px
  p
    font-size:16px
    r()
    font-weight: 300
    margin-bottom: 23px
    line-height: 1.55
    color #724f62
    a
      color #724f62
      border-bottom: solid 1px rgba(#aa899b, .45);

@media only screen and (min-width: 1921px)
  .s-story
    h2
      font-size: 26px
    p
      font-size: 18px

@media (max-width: 880px)
  .s-story
    h2
      margin-bottom: 17px
    .container
      padding: 19% 120px 40.6%;
      display: block
    &__inner
      max-width none

@media (max-width: 640px)
  .s-story
    .container
      max-width 500px
      padding: 14px 15px 50px
