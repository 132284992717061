.a-logo
  display block
  .svg-logo-vert
    display: none

@media (max-width: 880px)
  .a-logo
    .s-footer & .svg-logo-vert
      display: block;
      width: 116px;
      height: 114px;
      margin-bottom: 31px;
    .s-footer & .svg-logo
      display: none


@media (max-width: 640px)
  .a-logo
    .s-footer & .svg-logo-vert
      margin-bottom: 38px;
