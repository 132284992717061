.l-faq
  display block
  &__question
    font-size: 24px
    font-weight: 300
    padding 5px 0
    color #33691E
    margin-bottom: 11px;
    line-height: 1.3;
  &__answer
    color #5E3D4F
    font-size: 16px
    font-weight: 300
    line-height: 1.5
  &__item
    &:not(:first-child)
      margin-top: 36px
      .p-index &
        margin-top: 0
  &__item
    .p-index &
      display: none
    ._current-faq-1 &:nth-child(1)
      display: block
    ._current-faq-2 &:nth-child(2)
      display: block
    ._current-faq-3 &:nth-child(3)
      display: block
    ._current-faq-4 &:nth-child(4)
      display: block
    ._current-faq-5 &:nth-child(5)
      display: block
    ._current-faq-6 &:nth-child(6)
      display: block
    ._current-faq-7 &:nth-child(7)
      display: block
    ._current-faq-8 &:nth-child(8)
      display: block

@media (max-width: 740px)
  .l-faq
    &__question
      font-size: 16px
      margin-bottom: 3px
      line-height: 1.5
    &__item
      &:not(:first-child)
        margin-top: 28px
