.m-footer
  display: flex
  &__item
    &:not(:first-child)
      margin-left: 15px
  &__link
    display: block
    padding 0 25px
    line-height: 40px
    font-size: 14px
    letter-spacing: 1.4px
    color $primary
    .desktop &
      trc()
      &:hover
      &:focus
        color darken($primary, 70%)

@media (max-width: 1070px)
  .m-footer
    &__item
      &:not(:first-child)
        margin-left: 12px
    &__link
      padding 0 20px

@media (max-width: 880px)
  .m-footer
    margin-bottom: 22px
    &__link
      padding 0 23px
      letter-spacing: 1.4px;


@media (max-width: 640px)
  .m-footer
    flex-wrap wrap
    justify-content center
    &__item
      &:not(:first-child)
        margin-left: 0
    &__link
      padding 0 19px
      letter-spacing: 1.4px;
