.m-social
  display: flex
  align-items: center
  flex-direction row-reverse
  &__list
    display: flex
  &__promo
    width: 110px
    margin-right: 5px
    img
      width: 100%
      height: auto
  &._sticky
    position: absolute
    left: -10px
    bottom: calc(2vh + 20px);
    flex-direction row
    .m-social__list
      flex-direction column
  &__item
    &:not(:first-child)
      margin-left: 11px
      ._sticky &
        margin-left: 0
  &__link
    width: 40px
    height: 40px
    position: relative
    display: block
    .desktop &
      svg
        trf()
      &:hover svg
      &:focus svg
        fill darken(#C5E1A5, 30%)

  svg
    hvb()
  .svg-fb
    fill #C5E1A5
    width: 9px
    height: 20px
  .svg-tw
    fill #C5E1A5
    width: 21px
    height: 16px
  .svg-email
    fill #C5E1A5
    width: 19px
    height: 14px
    color #F1F8E9

@media (max-width: 1024px)
  .m-social
    &._sticky
      left: 20px

@media (max-width: 880px)
  .m-social
    &._sticky
      left: 13px

@media (max-width: 640px)
  .m-social
    &._sticky
      display: none
