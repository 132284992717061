.s-header
  display block
  position: fixed
  left: 0
  right: 0
  width: 100%
  top: 0
  padding: 35px 0
  z-index: 1
  trt()
  &:before
    ps()
    height: 120%
    width: 100%
    left: 0
    top: 0
    opacity: 0
    background linear-gradient(to bottom, #e1edd4 51%, rgba(#e1edd4, 0))
    pointer-events none
    touch-action none
    tro()
    ._scrolled &
      opacity: 1
    ._1 &
      opacity: 1
      background: url("../img/overlay.png") #dcedc8
  .container
    display: flex
    max-width: 1180px;
    position: relative
  .svg-logo
    height: 61px;
    width: 160px;
    margin-top: -13px;
  &__image
    position: absolute
    top: 70px
    left: 20px
    opacity: 0.6
    display: block
    tro()
    width: 100px
    &:hover
      opacity: 1
    img
      width: 100%
      height: auto;

@media (max-width: 1024px)
  .s-header
    padding-top: 30px
    .m-site
      display: none
    &__image
      opacity: 0.7
      top: -25px
      right: 20px
      margin: auto
      width: 80px

@media (max-width: 640px)
  .s-header
    .svg-logo
      height: 33px;
      width: 91px;
      margin-top: -14px;
    &__image
      width: 60px
