.modal-default
  position: fixed
  top: 0
  left: 0
  width: 100%
  padding: 30px
  background rgba(#000, 0.7)
  height: 100%
  font-size 1.6rem
  font-weight 300
  z-index: 3
  opacity 0
  text-align: center
  pointer-events none
  transform translateX(110%)
  transition opacity .25s ease-out, transform .001s ease-out .25s
  vam-ps()
  &__inner
    position: relative
    vam()
    max-width 1140px
    padding: 30px
    background #f1f7e9
    text-align: left;
    border-radius 35px;
  [class*='_open-modal-'] &
    pointer-events all
    transform none
    transition opacity .25s ease-out, transform .001s ease-out
  &__close
    position: absolute
    width: 60px
    height: 60px
    border-radius 50%
    background rgba(#131313, 0.45)
    top: 14px
    right: 14px
    &:before
    &:after
      hv()
      width: 36px
      height: 2px
      background #fff
    &:before
      transform rotate(45deg) translateZ(0)
    &:after
      transform rotate(-45deg) translateZ(0)
      //.desktop &
      //  &:hover
      //    &:after
      //    &:before
      //      background #6d6d6d

@media only screen and (max-width: $tablet)
  .modal-default
    cursor pointer

@media only screen and (max-height: 900px)
  .modal-default
    padding 0
    &__content
      max-height: 100vh




