@keyframes typedjsBlink
  50% { opacity: 0.0; }

@-webkit-keyframes typedjsBlink
  0% { opacity: 1; }
  50% { opacity: 0.0; }
  100% { opacity: 1; }


.s-greeting
  text-align: center
  &._step-2
    ._1
    ._etc
      opacity: 1
    .s-greeting
      &__link-outer
        opacity: 1
  ._relative
    position: relative
  ._1
    position: absolute
    left: 0
    bottom: 0;
    white-space nowrap
    opacity: 0
    transition opacity .7s ease-out
  ._etc
    opacity: 0
    transition opacity .7s ease-out 0.7s

    .typed-cursor
      opacity: 1;
      animation: typedjsBlink 0.7s infinite;
      -webkit-animation: typedjsBlink 0.7s infinite;
      animation: typedjsBlink 0.7s infinite;

    .typed-fade-out
      opacity: 0;
      transition: opacity .25s;
      -webkit-animation: 0;
      animation: 0;

  &__outer
    max-width 1140px
    position: relative
    margin 0 auto
  .container
    padding 0 190px 0 100px
    min-height calc(100vh - 100px)
    display: inline-flex
    align-items flex-start
    justify-content center
    flex-direction column
    text-align: left;
  &__title
    position: relative
    font-size: 48px
    font-weight: 300
    margin-bottom: 20px
    letter-spacing: .2px;
    line-height: 1.33
    min-width 470px
    color #79586a
    b
      font-weight: 400
  &__link
    line-height: 48px;
    border-radius: 24px;
    display: inline-block
    background-image: linear-gradient(128deg, #3c1b2d, #5e3d4f);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12), 2px 2px 26px 0 #c5e1a5;
    border: solid 1px #7b5a6c;
    m()
    font-size: 20px;
    letter-spacing: 0
    color: #fff;
    padding: 0 36px;
    opacity: 1
    tro()
    &-outer
      opacity: 0
      transition opacity .7s ease-out 1.9s
    .desktop &
      &:hover
      &:focus
        opacity: .9

@media (max-width: 880px)
  .s-greeting
    .container
      padding: 0 135px 70px 0;


@media (max-width: 640px)
  .s-greeting
    .container
      padding: 0 30px 150px 27px
    &__title
      min-width 200px
      font-size: 24px
      margin-bottom: 15px;
    &__link
      font-size: 15px;
      color: #fff;
      padding: 0 26px;
      border-radius: 18px
      line-height: 36px
