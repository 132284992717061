.pr-feature
  display block
  svg
    width: 86px
    height: 83px
  &__svg-wrap
    height: 100px
    display: flex
    align-items flex-end
  &__title
    r()
    font-weight: 600
    font-size: 16px;
    line-height: 2;
    letter-spacing: -0.1px;
    margin-bottom: -3px
    ._roam &
      color: #29081a;
    ._save &
      color: #1a237e;
    ._spark &
      color: #33691e;
  &._roam
    img
      margin: 0 0 0px -15px;
      width: 85px;
      height: 85px
  &._save
    img
      margin: 0 0 18px -4px;
      width: 52px
      height: 68px
  &._spark
    img
      margin: 0 0 17px 2px;
      width: 54px
      height: 80px
  &__text
    r()
    font-size: 14px;
    line-height: 1.75
    letter-spacing: -0.1px;
    text-align: left;
    color: #5e3d4f;

@media only screen and (min-width: 1921px)
  .pr-feature
    &__title
      font-size: 20px
    &__text
      font-size: 16px


@media only screen and (max-width: 640px)
  .pr-feature
    &._spark
      svg
        margin 0 0 -4px -24px
