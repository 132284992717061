.l-features
  display: flex
  align-items flex-start
  margin 0 -50px
  &__item
    flex-basis 33.333%
    width 33.333%
    padding 0 50px

@media (max-width: 1070px)
  .l-features
    margin 0 -40px
    &__item
      padding 0 20px

@media (max-width: 1070px)
  .l-features
    margin 0 -30px
    &__item
      padding 0 12px


@media (max-width: 640px)
  .l-features
    margin: 0
    display: block
    &__item
      width: 100%
      &:not(:first-child)
        margin-top: 47px
