.s-faq
  flex-grow: 1;
  .container
    padding 5.9% 30px 29%
    max-width: 610px;
  &__title
    color #3C1B2D
    margin-bottom: 24px
    font-size: 48px

@media (max-width: 880px)
  .s-faq
    .container
      padding 50px 30px

@media (max-width: 740px)
  .s-faq
    .container
      padding 0 14px 29%
    &__title
      margin-bottom: 37px
      font-size: 48px
