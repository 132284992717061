@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Regular.woff") format("woff");
  font-weight: 400;
  font-style normal
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style normal
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Hairline.woff") format("woff");
  font-weight: 200;
  font-style normal
}

@font-face {
  font-family: 'MyriadPro';
  src: url('../fonts/MyriadPro-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
