.wrapper
  min-height 100%
  min-width 320px
  display: flex
  flex-direction column
  align-items stretch
  overflow hidden
  position: relative
  background url(../img/desktop.jpg) center
  background-size cover
  padding-top: 100px
  .p-faq &
    background-image url(../img/faq-bg.jpg)
    background-position bottom center
  .p-root &
    padding-top: 0
    background-color: #DCEDC8;
    background-image: url(../img/overlay.png);
  ._ie &
    display: block
  &__content
    z-index: 0
    position: relative
    flex-grow 1
    display: flex
    justify-content: center
    //min-height: calc(100vh - 100px)
    height: 100vh
.container
  max-width 1240px
  min-width 320px
  margin 0 auto
  padding 0 20px

._cover
  width: 100%
  height: 100%

._2
  .typeform-widget
    padding-top: 150px
._3
  .wrapper__content
    z-index: 2
i
  font-style: italic;
  m()

img[data-object-fit="contain"]
  object-fit: contain

img[data-object-fit="cover"]
  object-fit: cover

.typeform-modal .typeform-close
  display: none

[data-qa="close-button-mobile"]
  display: none !important

@media (max-width: 880px)
  .wrapper
    background url(../img/tablet.jpg) center
    background-size cover
    .s-footer
      position: relative
      &:before
        ps()
        width: 100%
        height 100%
        left: 0
        top: 0
        background url(../img/tablet-footer.jpg) top center no-repeat #fff
        background-size 100% auto

@media (max-width: 740px)
  .wrapper
    background url(../img/mobile.jpg) center
    background-size cover
    .s-footer:before
      top -40px
      background url(../img/mobile-footer.png) top center no-repeat
  ._2
    .wrapper
      &__content
        padding-top: 60px
        height calc(100vh - 60px)
    .typeform-widget
      padding-top: 0
  .typeform-modal
    top: 60px!important
    height calc(100% - 60px) !important
