.jq-selectbox
  vertical-align: middle;
  cursor: pointer;
  width: 100%

.jq-selectbox__select
  height: 45px;
  width: 100%
  padding: 0 40px 0 28px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;

.jq-selectbox__select-text
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size 1.6rem
  font-weight: 400
  line-height 45px
  color #323131

.jq-selectbox__trigger
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;

.jq-selectbox__trigger-arrow
  position: absolute;
  top: 20px;
  right: 19px;
  width: 0;
  height: 0;
  border-top 7px solid #252932;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;

.jq-selectbox__dropdown
  box-sizing: border-box;
  width: 100%;
  margin: -4px 0 0;
  padding: 0 0 5px 0;
  border-bottom 1px solid #c5c5c5;
  border-right 1px solid #c5c5c5;
  border-left 1px solid #c5c5c5;
  border-radius: 3px;
  font-size 1.6rem
  font-weight 400
  background #fff

.jq-selectbox ul
  margin: 0;
  padding: 0;

.jq-selectbox li
  min-height: 18px;
  padding: 3px 28px 5px;
  white-space: nowrap;
  color #323131

.jq-selectbox li.sel
  background-color: #f5f5f5;
  display: none

.jq-selectbox li:hover
  background-color: #f5f5f5;

.jq-selectbox li.disabled {
	color: #AAA;
}
.jq-selectbox li.disabled:hover {
    background: none;
}
.jq-selectbox li.optgroup {
    font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}
.jq-selectbox li.option {
	padding-left: 25px;
}


@media only screen and (max-width: $mobile)
  .jq-selectbox__select
    height: 30px;
    padding 0 20px 0 17px
  .jq-selectbox__select-text
    font-size 1.1rem
    line-height: 30px
  .jq-selectbox__trigger-arrow
    position: absolute;
    top: 12px;
    right: 11px;
    border-width 5px
  .jq-selectbox__dropdown
    font-size 1.1rem
  .jq-selectbox li
    min-height: 18px;
    padding: 3px 17px 5px;


