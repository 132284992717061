.btn-scroll
  display block
  transform-origin center
  transform rotate(90deg)
  text-transform: uppercase
  width: 73px;
  height: 16px;
  r()
  font-size: 14px;
  letter-spacing: 2.6px;
  text-align: left;
  color: #aa899b;
  font-weight: 400;
  position: fixed;
  right: 100px
  user-select none
  bottom: calc(5.2vh + 100px);
  &:before
    v()
    left: 105%
    width: 61px;
    height: 1px;
    background linear-gradient(to right, #aa899b, rgba(170, 137, 155, 0))


@media (max-width: 1024px)
  .btn-scroll
    right: 0

@media (max-width: 880px)
  .btn-scroll
    right: -5px


@media (max-width: 640px)
  .btn-scroll
    display: none
