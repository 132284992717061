.s-features
  .container
    padding 11.2% 100px 10%
    display: flex
    align-items flex-start
    justify-content center
    flex-direction column
    max-width: 1144px;

@media (max-width: 1070px)
  .s-features
    .container
      padding 5.2% 100px 10%


@media (max-width: 880px)
  .s-features
    .container
      padding 5.2% 80px 10%

@media (max-width: 640px)
  .s-features
    .container
      max-width 500px
      padding 78px 3px 50px
