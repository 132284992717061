.s-dna
  .container
    padding 20.5% 100px 10%
    max-width 1140px
    display: flex
    align-items flex-start
    justify-content center
    flex-direction column
  &__inner
    max-width 464px
  h2
    font-size: 24px;
    letter-spacing: -1.2px
    color: #33691e;
    margin-bottom: 18px
  p
    font-size: 16px
    line-height: 1.5;
    letter-spacing: -0.2px;
    r()
    font-weight: 300
    margin-bottom: 24px
    color #724f62
    a
      color #303F9F
      .desktop &
        trc()
        &:hover
        &:focus
          color darken(#303F9F,20%)

@media only screen and (min-width: 1921px)
  .s-dna
    h2
      font-size: 32px
    p
      font-size: 18px


@media only screen and (max-width: 1070px)
  .s-dna
    .container
      padding 10% 100px 10%

@media only screen and (max-width: 880px)
  .s-dna
    h2
      margin-bottom: 25px
    .container
      padding: 42.9% 122px 28% 122px
    &__inner
      max-width: 505px;


@media only screen and (max-width: 640px)
  .s-dna
    h2
      margin-bottom: 11px
      font-size: 16px
    .container
      max-width 500px
      padding: 12.8% 20px 20% 20px
