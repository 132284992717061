.btn-menu
  width: 40px
  height: 40px
  position: fixed
  right: 20px
  top: 25px
  display: none
  z-index: 3
  svg
    width: 40px
    height: 40px
    fill #502F41
    hvb()
  &__top
  &__bottom
    transform scale(1.5)
    transform-origin center
    trt()
  ._open-menu &__top
    transform scale(1.5) translateY(-4px) rotate(45deg)
  ._open-menu &__bottom
    transform scale(1.5) translateY(4px) rotate(-45deg)

@media (max-width: 1024px)
  .btn-menu
    display: block

@media (max-width: 540px)
  .btn-menu
    right: 12px;
    top: 11px;
