/*
p -- page
s -- section
b -- block
pr -- block preview (pr-xxs, pr-xs, pr-s, pr-m, pr) if different sizes on site
f -- form
m -- menu
l -- list
sl -- sliders
svg -- svg grafix
i -- etc grafix
a -- img links, lone links, logos
t -- tables
title -- default titles
btn -- buttons
msg -- message - not content
modal -- modal windows
_error-*
_success-*
*/
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
strong,
abbr,
acronym,
blockquote,
q,
cite,
ins,
del,
dfn,
a,
div,
span,
pre,
hr,
address,
br,
b,
i,
sub,
big,
small,
tt,
table,
tr,
caption,
thead,
tbody,
tfoot,
col,
colgroup,
form,
input,
label,
textarea,
button,
fieldset,
legend,
select,
option,
ul,
ol,
li,
dl,
dt,
dd,
code,
var,
samp,
img,
object,
param,
map,
area {
  padding: 0;
  margin: 0;
  border: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
header,
footer,
section,
aside,
figure,
figcaption,
input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type=button],
input[type=submit],
button {
  cursor: pointer;
}
button {
  border: 0;
}
:focus {
  outline: none;
}
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
  outline: none;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
input,
textarea {
  resize: none;
  user-select: text;
}
::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}
a {
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
:disabled,
[disabled],
[aria-disabled="true"] {
  cursor: not-allowed;
}
video {
  background-size: cover;
  background-position: center;
}
img {
  display: block;
}
button {
  background: transparent;
  overflow: visible;
}
textarea {
  overflow-y: auto;
  background: transparent;
}
input,
textarea,
select,
button {
  -webkit-appearance: none;
  border-radius: 0;
}
[type="reset"] {
  cursor: pointer;
}
html,
body {
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-focus-ring-color: rgba(255,255,255,0);
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Hairline.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'MyriadPro';
  src: url("../fonts/MyriadPro-Regular.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}
html,
body {
  font-size: 62.5%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}
@media only screen and (max-width: 1300px) and (min-width: 1025px) {
  html,
  body {
    font-size: 50%;
  }
}
.jq-selectbox {
  vertical-align: middle;
  cursor: pointer;
  width: 100%;
}
.jq-selectbox__select {
  height: 45px;
  width: 100%;
  padding: 0 40px 0 28px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
}
.jq-selectbox__select-text {
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 45px;
  color: #323131;
}
.jq-selectbox__trigger {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
}
.jq-selectbox__trigger-arrow {
  position: absolute;
  top: 20px;
  right: 19px;
  width: 0;
  height: 0;
  border-top: 7px solid #252932;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}
.jq-selectbox__dropdown {
  box-sizing: border-box;
  width: 100%;
  margin: -4px 0 0;
  padding: 0 0 5px 0;
  border-bottom: 1px solid #c5c5c5;
  border-right: 1px solid #c5c5c5;
  border-left: 1px solid #c5c5c5;
  border-radius: 3px;
  font-size: 1.6rem;
  font-weight: 400;
  background: #fff;
}
.jq-selectbox ul {
  margin: 0;
  padding: 0;
}
.jq-selectbox li {
  min-height: 18px;
  padding: 3px 28px 5px;
  white-space: nowrap;
  color: #323131;
}
.jq-selectbox li.sel {
  background-color: #f5f5f5;
  display: none;
}
.jq-selectbox li:hover {
  background-color: #f5f5f5;
}
.jq-selectbox li.disabled {
  color: #aaa;
}
.jq-selectbox li.disabled:hover {
  background: none;
}
.jq-selectbox li.optgroup {
  font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
  background: none;
  color: #231f20;
  cursor: default;
}
.jq-selectbox li.option {
  padding-left: 25px;
}
@media only screen and (max-width: $mobile) {
  .jq-selectbox__select {
    height: 30px;
    padding: 0 20px 0 17px;
  }
  .jq-selectbox__select-text {
    font-size: 1.1rem;
    line-height: 30px;
  }
  .jq-selectbox__trigger-arrow {
    position: absolute;
    top: 12px;
    right: 11px;
    border-width: 5px;
  }
  .jq-selectbox__dropdown {
    font-size: 1.1rem;
  }
  .jq-selectbox li {
    min-height: 18px;
    padding: 3px 17px 5px;
  }
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.wrapper {
  min-height: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  background: url("../img/desktop.jpg") center;
  background-size: cover;
  padding-top: 100px;
}
.p-faq .wrapper {
  background-image: url("../img/faq-bg.jpg");
  background-position: bottom center;
}
.p-root .wrapper {
  padding-top: 0;
  background-color: #dcedc8;
  background-image: url("../img/overlay.png");
}
._ie .wrapper {
  display: block;
}
.wrapper__content {
  z-index: 0;
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.container {
  max-width: 1240px;
  min-width: 320px;
  margin: 0 auto;
  padding: 0 20px;
}
._cover {
  width: 100%;
  height: 100%;
}
._2 .typeform-widget {
  padding-top: 150px;
}
._3 .wrapper__content {
  z-index: 2;
}
i {
  font-style: italic;
  font-family: 'Montserrat', sans-serif;
}
img[data-object-fit="contain"] {
  object-fit: contain;
}
img[data-object-fit="cover"] {
  object-fit: cover;
}
.typeform-modal .typeform-close {
  display: none;
}
[data-qa="close-button-mobile"] {
  display: none !important;
}
@media (max-width: 880px) {
  .wrapper {
    background: url("../img/tablet.jpg") center;
    background-size: cover;
  }
  .wrapper .s-footer {
    position: relative;
  }
  .wrapper .s-footer:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url("../img/tablet-footer.jpg") top center no-repeat #fff;
    background-size: 100% auto;
  }
}
@media (max-width: 740px) {
  .wrapper {
    background: url("../img/mobile.jpg") center;
    background-size: cover;
  }
  .wrapper .s-footer:before {
    top: -40px;
    background: url("../img/mobile-footer.png") top center no-repeat;
  }
  ._2 .wrapper__content {
    padding-top: 60px;
    height: calc(100vh - 60px);
  }
  ._2 .typeform-widget {
    padding-top: 0;
  }
  .typeform-modal {
    top: 60px !important;
    height: calc(100% - 60px) !important;
  }
}
.a-logo {
  display: block;
}
.a-logo .svg-logo-vert {
  display: none;
}
@media (max-width: 880px) {
  .s-footer .a-logo .svg-logo-vert {
    display: block;
    width: 116px;
    height: 114px;
    margin-bottom: 31px;
  }
  .s-footer .a-logo .svg-logo {
    display: none;
  }
}
@media (max-width: 640px) {
  .s-footer .a-logo .svg-logo-vert {
    margin-bottom: 38px;
  }
}
.btn-menu {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 25px;
  display: none;
  z-index: 3;
}
.btn-menu svg {
  width: 40px;
  height: 40px;
  fill: #502f41;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.btn-menu__top,
.btn-menu__bottom {
  transform: scale(1.5);
  transform-origin: center;
  transition: transform 0.2s ease-out;
}
._open-menu .btn-menu__top {
  transform: scale(1.5) translateY(-4px) rotate(45deg);
}
._open-menu .btn-menu__bottom {
  transform: scale(1.5) translateY(4px) rotate(-45deg);
}
@media (max-width: 1024px) {
  .btn-menu {
    display: block;
  }
}
@media (max-width: 540px) {
  .btn-menu {
    right: 12px;
    top: 11px;
  }
}
.btn-scroll {
  display: block;
  transform-origin: center;
  transform: rotate(90deg);
  text-transform: uppercase;
  width: 73px;
  height: 16px;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  letter-spacing: 2.6px;
  text-align: left;
  color: #aa899b;
  font-weight: 400;
  position: fixed;
  right: 100px;
  user-select: none;
  bottom: calc(5.2vh + 100px);
}
.btn-scroll:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 105%;
  width: 61px;
  height: 1px;
  background: linear-gradient(to right, #aa899b, rgba(170,137,155,0));
}
@media (max-width: 1024px) {
  .btn-scroll {
    right: 0;
  }
}
@media (max-width: 880px) {
  .btn-scroll {
    right: -5px;
  }
}
@media (max-width: 640px) {
  .btn-scroll {
    display: none;
  }
}
.l-faq {
  display: block;
}
.l-faq__question {
  font-size: 24px;
  font-weight: 300;
  padding: 5px 0;
  color: #33691e;
  margin-bottom: 11px;
  line-height: 1.3;
}
.l-faq__answer {
  color: #5e3d4f;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}
.l-faq__item:not(:first-child) {
  margin-top: 36px;
}
.p-index .l-faq__item:not(:first-child) {
  margin-top: 0;
}
.p-index .l-faq__item {
  display: none;
}
._current-faq-1 .l-faq__item:nth-child(1) {
  display: block;
}
._current-faq-2 .l-faq__item:nth-child(2) {
  display: block;
}
._current-faq-3 .l-faq__item:nth-child(3) {
  display: block;
}
._current-faq-4 .l-faq__item:nth-child(4) {
  display: block;
}
._current-faq-5 .l-faq__item:nth-child(5) {
  display: block;
}
._current-faq-6 .l-faq__item:nth-child(6) {
  display: block;
}
._current-faq-7 .l-faq__item:nth-child(7) {
  display: block;
}
._current-faq-8 .l-faq__item:nth-child(8) {
  display: block;
}
@media (max-width: 740px) {
  .l-faq__question {
    font-size: 16px;
    margin-bottom: 3px;
    line-height: 1.5;
  }
  .l-faq__item:not(:first-child) {
    margin-top: 28px;
  }
}
.l-features {
  display: flex;
  align-items: flex-start;
  margin: 0 -50px;
}
.l-features__item {
  flex-basis: 33.333%;
  width: 33.333%;
  padding: 0 50px;
}
@media (max-width: 1070px) {
  .l-features {
    margin: 0 -40px;
  }
  .l-features__item {
    padding: 0 20px;
  }
}
@media (max-width: 1070px) {
  .l-features {
    margin: 0 -30px;
  }
  .l-features__item {
    padding: 0 12px;
  }
}
@media (max-width: 640px) {
  .l-features {
    margin: 0;
    display: block;
  }
  .l-features__item {
    width: 100%;
  }
  .l-features__item:not(:first-child) {
    margin-top: 47px;
  }
}
.m-footer {
  display: flex;
}
.m-footer__item:not(:first-child) {
  margin-left: 15px;
}
.m-footer__link {
  display: block;
  padding: 0 25px;
  line-height: 40px;
  font-size: 14px;
  letter-spacing: 1.4px;
  color: #5e3d4f;
}
.desktop .m-footer__link {
  transition: color 0.2s ease-out;
}
.desktop .m-footer__link:hover,
.desktop .m-footer__link:focus {
  color: #1c1218;
}
@media (max-width: 1070px) {
  .m-footer__item:not(:first-child) {
    margin-left: 12px;
  }
  .m-footer__link {
    padding: 0 20px;
  }
}
@media (max-width: 880px) {
  .m-footer {
    margin-bottom: 22px;
  }
  .m-footer__link {
    padding: 0 23px;
    letter-spacing: 1.4px;
  }
}
@media (max-width: 640px) {
  .m-footer {
    flex-wrap: wrap;
    justify-content: center;
  }
  .m-footer__item:not(:first-child) {
    margin-left: 0;
  }
  .m-footer__link {
    padding: 0 19px;
    letter-spacing: 1.4px;
  }
}
.m-site {
  display: flex;
}
.s-header .m-site {
  margin-left: auto;
}
.s-menu .m-site {
  margin-top: auto;
  margin-bottom: auto;
}
.m-site._vert {
  flex-direction: column;
}
.m-site__item:not(:first-child) {
  margin-left: 14px;
}
._vert .m-site__item:not(:first-child) {
  margin-left: 0;
  margin-top: 30px;
}
.m-site__link {
  display: block;
  padding: 0 25px;
  line-height: 40px;
  font-size: 14px;
  letter-spacing: 1.4px;
  color: #5e3d4f;
}
.desktop .m-site__link {
  transition: color 0.2s ease-out;
}
.desktop .m-site__link:hover,
.desktop .m-site__link:focus {
  color: #1c1218;
}
._vert .m-site__link {
  width: 100%;
  text-align: center;
}
.m-site__link._out {
  border-radius: 20px;
  color: #fff;
  background: #5e3d4f;
  margin-left: 25px;
  letter-spacing: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.24), 0 0 2px 0 rgba(0,0,0,0.12), 2px 2px 26px 0 #c5e1a5;
}
.desktop .m-site__link._out {
  transition: opacity 0.2s ease-out;
}
.desktop .m-site__link._out:hover,
.desktop .m-site__link._out:focus {
  color: #fff;
  opacity: 0.9;
}
._vert .m-site__link._out {
  margin-left: 0;
}
@media (max-width: 880px) {
  ._vert .m-site__item:not(:first-child) {
    margin-top: 20px;
  }
}
@media (max-width: 660px) {
  ._vert .m-site__item:not(:first-child) {
    margin-top: 15px;
  }
}
@media (orientation: landscape) and (max-width: 740px) {
  .m-site__link {
    line-height: 32px;
  }
  ._vert .m-site__item:not(:first-child) {
    margin-top: 7px;
  }
}
.m-social {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.m-social__list {
  display: flex;
}
.m-social__promo {
  width: 110px;
  margin-right: 5px;
}
.m-social__promo img {
  width: 100%;
  height: auto;
}
.m-social._sticky {
  position: absolute;
  left: -10px;
  bottom: calc(2vh + 20px);
  flex-direction: row;
}
.m-social._sticky .m-social__list {
  flex-direction: column;
}
.m-social__item:not(:first-child) {
  margin-left: 11px;
}
._sticky .m-social__item:not(:first-child) {
  margin-left: 0;
}
.m-social__link {
  width: 40px;
  height: 40px;
  position: relative;
  display: block;
}
.desktop .m-social__link svg {
  transition: fill 0.2s ease-out;
}
.desktop .m-social__link:hover svg,
.desktop .m-social__link:focus svg {
  fill: #8cc44d;
}
.m-social svg {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.m-social .svg-fb {
  fill: #c5e1a5;
  width: 9px;
  height: 20px;
}
.m-social .svg-tw {
  fill: #c5e1a5;
  width: 21px;
  height: 16px;
}
.m-social .svg-email {
  fill: #c5e1a5;
  width: 19px;
  height: 14px;
  color: #f1f8e9;
}
@media (max-width: 1024px) {
  .m-social._sticky {
    left: 20px;
  }
}
@media (max-width: 880px) {
  .m-social._sticky {
    left: 13px;
  }
}
@media (max-width: 640px) {
  .m-social._sticky {
    display: none;
  }
}
.modal-default {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px;
  background: rgba(0,0,0,0.7);
  height: 100%;
  font-size: 1.6rem;
  font-weight: 300;
  z-index: 3;
  opacity: 0;
  text-align: center;
  pointer-events: none;
  transform: translateX(110%);
  transition: opacity 0.25s ease-out, transform 0.001s ease-out 0.25s;
  font-size: 0;
}
.modal-default:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}
.modal-default__inner {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  padding: 30px;
  background: #f1f7e9;
  text-align: left;
  border-radius: 35px;
}
[class*='_open-modal-'] .modal-default {
  pointer-events: all;
  transform: none;
  transition: opacity 0.25s ease-out, transform 0.001s ease-out;
}
.modal-default__close {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(19,19,19,0.45);
  top: 14px;
  right: 14px;
}
.modal-default__close:before,
.modal-default__close:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 36px;
  height: 2px;
  background: #fff;
}
.modal-default__close:before {
  transform: rotate(45deg) translateZ(0);
}
.modal-default__close:after {
  transform: rotate(-45deg) translateZ(0);
}
@media only screen and (max-width: $tablet) {
  .modal-default {
    cursor: pointer;
  }
}
@media only screen and (max-height: 900px) {
  .modal-default {
    padding: 0;
  }
  .modal-default__content {
    max-height: 100vh;
  }
}
.modal-faq {
  display: block;
}
._open-modal-faq .modal-faq {
  opacity: 1;
}
.pr-feature {
  display: block;
}
.pr-feature svg {
  width: 86px;
  height: 83px;
}
.pr-feature__svg-wrap {
  height: 100px;
  display: flex;
  align-items: flex-end;
}
.pr-feature__title {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 2;
  letter-spacing: -0.1px;
  margin-bottom: -3px;
}
._roam .pr-feature__title {
  color: #29081a;
}
._save .pr-feature__title {
  color: #1a237e;
}
._spark .pr-feature__title {
  color: #33691e;
}
.pr-feature._roam img {
  margin: 0 0 0px -15px;
  width: 85px;
  height: 85px;
}
.pr-feature._save img {
  margin: 0 0 18px -4px;
  width: 52px;
  height: 68px;
}
.pr-feature._spark img {
  margin: 0 0 17px 2px;
  width: 54px;
  height: 80px;
}
.pr-feature__text {
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: -0.1px;
  text-align: left;
  color: #5e3d4f;
}
@media only screen and (min-width: 1921px) {
  .pr-feature__title {
    font-size: 20px;
  }
  .pr-feature__text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 640px) {
  .pr-feature._spark svg {
    margin: 0 0 -4px -24px;
  }
}
.s-dna .container {
  padding: 20.5% 100px 10%;
  max-width: 1140px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.s-dna__inner {
  max-width: 464px;
}
.s-dna h2 {
  font-size: 24px;
  letter-spacing: -1.2px;
  color: #33691e;
  margin-bottom: 18px;
}
.s-dna p {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  margin-bottom: 24px;
  color: #724f62;
}
.s-dna p a {
  color: #303f9f;
}
.desktop .s-dna p a {
  transition: color 0.2s ease-out;
}
.desktop .s-dna p a:hover,
.desktop .s-dna p a:focus {
  color: #26327f;
}
@media only screen and (min-width: 1921px) {
  .s-dna h2 {
    font-size: 32px;
  }
  .s-dna p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1070px) {
  .s-dna .container {
    padding: 10% 100px 10%;
  }
}
@media only screen and (max-width: 880px) {
  .s-dna h2 {
    margin-bottom: 25px;
  }
  .s-dna .container {
    padding: 42.9% 122px 28% 122px;
  }
  .s-dna__inner {
    max-width: 505px;
  }
}
@media only screen and (max-width: 640px) {
  .s-dna h2 {
    margin-bottom: 11px;
    font-size: 16px;
  }
  .s-dna .container {
    max-width: 500px;
    padding: 12.8% 20px 20% 20px;
  }
}
.s-faq {
  flex-grow: 1;
}
.s-faq .container {
  padding: 5.9% 30px 29%;
  max-width: 610px;
}
.s-faq__title {
  color: #3c1b2d;
  margin-bottom: 24px;
  font-size: 48px;
}
@media (max-width: 880px) {
  .s-faq .container {
    padding: 50px 30px;
  }
}
@media (max-width: 740px) {
  .s-faq .container {
    padding: 0 14px 29%;
  }
  .s-faq__title {
    margin-bottom: 37px;
    font-size: 48px;
  }
}
.s-features .container {
  padding: 11.2% 100px 10%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 1144px;
}
@media (max-width: 1070px) {
  .s-features .container {
    padding: 5.2% 100px 10%;
  }
}
@media (max-width: 880px) {
  .s-features .container {
    padding: 5.2% 80px 10%;
  }
}
@media (max-width: 640px) {
  .s-features .container {
    max-width: 500px;
    padding: 78px 3px 50px;
  }
}
.s-footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3% 20px 70px;
  max-width: 1200px;
  position: relative;
}
.s-footer .svg-logo {
  width: 100px;
  height: 50px;
}
@media (max-width: 1070px) {
  .s-footer .container {
    padding: 3% 20px 60px;
  }
}
@media (max-width: 1024px) {
  .s-footer .container {
    padding: 120px 20px 80px;
    flex-direction: column;
  }
}
@-webkit-keyframes typedjsBlink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.s-greeting {
  text-align: center;
}
.s-greeting._step-2 ._1,
.s-greeting._step-2 ._etc {
  opacity: 1;
}
.s-greeting._step-2 .s-greeting__link-outer {
  opacity: 1;
}
.s-greeting ._relative {
  position: relative;
}
.s-greeting ._1 {
  position: absolute;
  left: 0;
  bottom: 0;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.7s ease-out;
}
.s-greeting ._etc {
  opacity: 0;
  transition: opacity 0.7s ease-out 0.7s;
}
.s-greeting ._etc .typed-cursor {
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}
.s-greeting ._etc .typed-fade-out {
  opacity: 0;
  transition: opacity 0.25s;
  -webkit-animation: 0;
  animation: 0;
}
.s-greeting__outer {
  max-width: 1140px;
  position: relative;
  margin: 0 auto;
}
.s-greeting .container {
  padding: 0 190px 0 100px;
  min-height: calc(100vh - 100px);
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.s-greeting__title {
  position: relative;
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 20px;
  letter-spacing: 0.2px;
  line-height: 1.33;
  min-width: 470px;
  color: #79586a;
}
.s-greeting__title b {
  font-weight: 400;
}
.s-greeting__link {
  line-height: 48px;
  border-radius: 24px;
  display: inline-block;
  background-image: linear-gradient(128deg, #3c1b2d, #5e3d4f);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.24), 0 0 2px 0 rgba(0,0,0,0.12), 2px 2px 26px 0 #c5e1a5;
  border: solid 1px #7b5a6c;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  color: #fff;
  padding: 0 36px;
  opacity: 1;
  transition: opacity 0.2s ease-out;
}
.s-greeting__link-outer {
  opacity: 0;
  transition: opacity 0.7s ease-out 1.9s;
}
.desktop .s-greeting__link:hover,
.desktop .s-greeting__link:focus {
  opacity: 0.9;
}
@media (max-width: 880px) {
  .s-greeting .container {
    padding: 0 135px 70px 0;
  }
}
@media (max-width: 640px) {
  .s-greeting .container {
    padding: 0 30px 150px 27px;
  }
  .s-greeting__title {
    min-width: 200px;
    font-size: 24px;
    margin-bottom: 15px;
  }
  .s-greeting__link {
    font-size: 15px;
    color: #fff;
    padding: 0 26px;
    border-radius: 18px;
    line-height: 36px;
  }
}
@-moz-keyframes typedjsBlink {
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes typedjsBlink {
  50% {
    opacity: 0;
  }
}
@-o-keyframes typedjsBlink {
  50% {
    opacity: 0;
  }
}
@keyframes typedjsBlink {
  50% {
    opacity: 0;
  }
}
.s-header {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  padding: 35px 0;
  z-index: 1;
  transition: transform 0.2s ease-out;
}
.s-header:before {
  display: block;
  content: "";
  position: absolute;
  height: 120%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  background: linear-gradient(to bottom, #e1edd4 51%, rgba(225,237,212,0));
  pointer-events: none;
  touch-action: none;
  transition: opacity 0.2s ease-out;
}
._scrolled .s-header:before {
  opacity: 1;
}
._1 .s-header:before {
  opacity: 1;
  background: url("../img/overlay.png") #dcedc8;
}
.s-header .container {
  display: flex;
  max-width: 1180px;
  position: relative;
}
.s-header .svg-logo {
  height: 61px;
  width: 160px;
  margin-top: -13px;
}
.s-header__image {
  position: absolute;
  top: 70px;
  left: 20px;
  opacity: 0.6;
  display: block;
  transition: opacity 0.2s ease-out;
  width: 100px;
}
.s-header__image:hover {
  opacity: 1;
}
.s-header__image img {
  width: 100%;
  height: auto;
}
@media (max-width: 1024px) {
  .s-header {
    padding-top: 30px;
  }
  .s-header .m-site {
    display: none;
  }
  .s-header__image {
    opacity: 0.7;
    top: -25px;
    right: 20px;
    margin: auto;
    width: 80px;
  }
}
@media (max-width: 640px) {
  .s-header .svg-logo {
    height: 33px;
    width: 91px;
    margin-top: -14px;
  }
  .s-header__image {
    width: 60px;
  }
}
.s-menu {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 50px;
  z-index: 2;
  background-image: linear-gradient(316deg, #eef6e4, #d1e5bb);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-110%);
  opacity: 0;
  transition: transform 0.001s ease-out 0.35s, opacity 0.35s ease-out;
}
._open-menu .s-menu {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 0.35s ease-out;
}
@media (max-width: 1024px) {
  .s-menu {
    padding: 40px;
  }
}
@media (max-width: 640px) {
  .s-menu {
    padding: 30px;
  }
}
@media (orientation: landscape) and (max-width: 740px) {
  .s-menu {
    padding: 10px 30px;
  }
}
.s-story .container {
  padding: 11.3% 100px 19%;
  max-width: 1150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.s-story__inner {
  max-width: 460px;
}
.s-story h2 {
  font-size: 24px;
  letter-spacing: -1.2px;
  color: #33691e;
  margin-bottom: 19px;
}
.s-story p {
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  margin-bottom: 23px;
  line-height: 1.55;
  color: #724f62;
}
.s-story p a {
  color: #724f62;
  border-bottom: solid 1px rgba(170,137,155,0.45);
}
@media only screen and (min-width: 1921px) {
  .s-story h2 {
    font-size: 26px;
  }
  .s-story p {
    font-size: 18px;
  }
}
@media (max-width: 880px) {
  .s-story h2 {
    margin-bottom: 17px;
  }
  .s-story .container {
    padding: 19% 120px 40.6%;
    display: block;
  }
  .s-story__inner {
    max-width: none;
  }
}
@media (max-width: 640px) {
  .s-story .container {
    max-width: 500px;
    padding: 14px 15px 50px;
  }
}
