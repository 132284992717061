.m-site
  display: flex
  .s-header &
    margin-left auto

  .s-menu &
    margin-top auto
    margin-bottom auto
  &._vert
    flex-direction column

  &__item
    &:not(:first-child)
      margin-left: 14px
      ._vert &
        margin-left: 0
        margin-top: 30px
  &__link
    display: block
    padding: 0 25px;
    line-height: 40px
    font-size: 14px
    letter-spacing: 1.4px
    color $primary
    .desktop &
      trc()
      &:hover
      &:focus
        color darken($primary, 70%)
    ._vert &
      width: 100%
      text-align: center
    &._out
      border-radius: 20px
      color #fff
      background $primary
      margin-left: 25px
      letter-spacing: 0
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12), 2px 2px 26px 0 #c5e1a5;
      .desktop &
        tro()
        &:hover
        &:focus
          color #fff
          opacity: .9
      ._vert &
        margin-left: 0

@media (max-width: 880px)
  .m-site
    &__item
      &:not(:first-child)
        ._vert &
          margin-top: 20px

@media (max-width: 660px)
  .m-site
    &__item
      &:not(:first-child)
        ._vert &
          margin-top: 15px

@media (orientation: landscape) and (max-width: 740px)
  .m-site
    &__link
      line-height: 32px
    &__item
      &:not(:first-child)
        ._vert &
          margin-top: 7px
