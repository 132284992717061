.s-footer
  .container
    display: flex
    justify-content space-between
    align-items center
    padding 3% 20px 70px
    max-width 1200px
    position: relative
  .svg-logo
    width: 100px
    height: 50px

@media (max-width: 1070px)
  .s-footer
    .container
      padding 3% 20px 60px

@media (max-width: 1024px)
  .s-footer
    .container
      padding 120px 20px 80px
      flex-direction column
